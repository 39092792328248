import React from 'react';

const EditWorksheet = ({
  initialTitle,
  onTitleChange,
  onFontSizeChange,
  fontSize,
  includeTitle,
  onIncludeTitleChange,
  includeNameDate,
  onIncludeNameDateChange
}) => {
  return (
    <div className="bg-white shadow-md rounded px-4 sm:px-8 py-4 sm:py-6 space-y-4">
      <h2 className="text-lg sm:text-xl font-semibold mb-3">Worksheet Details</h2>
      
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={initialTitle}
          onChange={(e) => onTitleChange(e.target.value)}
          className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
          placeholder="Worksheet Title"
        />
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={includeTitle}
            onChange={(e) => onIncludeTitleChange(e.target.checked)}
            className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
          />
          <span className="text-sm text-gray-700">Include</span>
        </label>
      </div>

      <div className="flex items-center space-x-4">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={includeNameDate}
            onChange={(e) => onIncludeNameDateChange(e.target.checked)}
            className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
          />
          <span className="text-sm text-gray-700">Include Name and Date fields</span>
        </label>
      </div>

      <div className="flex items-center space-x-4">
        <span className="text-sm text-gray-700">Font Size:</span>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => onFontSizeChange(-1)}
            className="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            -
          </button>
          <span className="text-sm font-medium">{fontSize}</span>
          <button
            onClick={() => onFontSizeChange(1)}
            className="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400"
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditWorksheet;