import React from 'react';

const AnswerKeyDisplay = ({ question, index }) => {
  const fixTextSpacing = (text) => {
    if (!text) return '';
    // Remove random spaces between letters within words
    return text.replace(/\b[A-Za-z]+\b/g, word => word.replace(/\s+/g, ''));
  };

  const renderAnswerContent = () => {
    switch (question.type) {
      case 'mcq':
        return (
          <div className="answer-content">
            <div className="font-semibold">
              Answer: Option {question.correctOption}
            </div>
            {question.explanation && (
              <p className="text-gray-600 mt-2 mb-4">
                {fixTextSpacing(question.explanation)}
              </p>
            )}
          </div>
        );

      case 'fillInBlanks':
        return (
          <div className="answer-content">
            <div className="font-semibold">Answer: {question.answer}</div>
            {question.explanation && (
              <p className="text-gray-600 mt-2 mb-4">
                {fixTextSpacing(question.explanation)}
              </p>
            )}
          </div>
        );

      case 'trueOrFalse':
        return (
          <div className="answer-content">
            <div className="font-semibold">
              Answer: {question.answer ? 'True' : 'False'}
            </div>
            {question.explanation && (
              <p className="text-gray-600 mt-2 mb-4">
                {fixTextSpacing(question.explanation)}
              </p>
            )}
          </div>
        );

      default:
        return (
          <div className="answer-content">
            <span>{fixTextSpacing(question.answer)}</span>
          </div>
        );
    }
  };

  return (
    <div className="answer-item mb-8 pb-4">
      <div className="flex items-start">
        <span className="mr-3 font-bold min-w-[1.5rem]">{index + 1}.</span>
        <div className="flex-1">
          {renderAnswerContent()}
        </div>
      </div>
    </div>
  );
};

export default AnswerKeyDisplay;