import React, { useEffect } from 'react';

const CustomGoogleAd = () => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Error loading Google Ad:', e);
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-format="fluid"
      data-ad-layout-key="-7d+ex+d-5b+81"
      data-ad-client="ca-pub-5639040448942846"
      data-ad-slot="7656556446"
    />
  );
};

export default CustomGoogleAd;