import posthog from 'posthog-js'
import ReactGA from "react-ga4";

export const initAnalytics = () => {
  // Initialize PostHog
  posthog.init('phc_os37GcyAw9Z9cKUA4eX88Hdz6iwDpf39YOQccOSKhWp', {
    api_host: 'https://us.i.posthog.com' // usually 'https://app.posthog.com' or your self-hosted URL
  })

  // Initialize Google Analytics
  ReactGA.initialize('G-HELPHQ23NM');
}

export const trackPageView = (path) => {
  // Track page view in PostHog
  posthog.capture('$pageview', { path: path })

  // Track page view in Google Analytics
  ReactGA.send({ hitType: "pageview", page: path });
}

export const trackEvent = (eventName, properties) => {
  // Track event in PostHog
  posthog.capture(eventName, properties)

  // Track event in Google Analytics
  ReactGA.event({
    category: "User Action",
    action: eventName,
    label: JSON.stringify(properties)
  });
}