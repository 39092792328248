import React, { useState, useEffect } from 'react';
import posthog from 'posthog-js';

const WorksheetForm = ({ onSubmit, initialData, isLoading }) => {
  const [formData, setFormData] = useState({
    topic: '',
    subject: 'English',
    gradeLevel: '5',
    numberOfQuestions: 5,
    questionTypes: ['mcq'] // Default to MCQ
  });

  useEffect(() => {
    if (initialData) {
      setFormData(prevState => ({
        ...prevState,
        ...initialData,
        questionTypes: initialData.questionTypes || ['mcq'] // Ensure backward compatibility
      }));
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleQuestionTypeChange = (e) => {
    const { value, checked } = e.target;
    setFormData(prevState => {
      let newTypes = checked 
        ? [...prevState.questionTypes, value]
        : prevState.questionTypes.filter(type => type !== value);
      
      // Ensure at least one type is selected
      if (newTypes.length === 0) {
        newTypes = ['mcq'];
      }

      // Track question type changes in PostHog
      posthog.capture(checked ? 'question_type_added' : 'question_type_removed', {
        action: checked ? 'added' : 'removed',
        questionType: value,
        totalTypesSelected: newTypes.length,
        currentTypes: newTypes,
        previousTypes: prevState.questionTypes
      });
      
      return {
        ...prevState,
        questionTypes: newTypes
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label htmlFor="topic" className="block text-sm font-medium text-gray-700 mb-1">Topic</label>
        <input
          type="text"
          id="topic"
          name="topic"
          value={formData.topic}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
          required
          placeholder="e.g., Figures of speech"
        />
      </div>

      <div>
        <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">Subject</label>
        <select
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
          required
        >
          <option value="English">English</option>
          <option value="Mathematics">Mathematics</option>
          <option value="Science">Science</option>
          <option value="History">History</option>
          <option value="Geography">Geography</option>
          <option value="Literature">Literature</option>
          <option value="Physics">Physics</option>
          <option value="Chemistry">Chemistry</option>
          <option value="Biology">Biology</option>
        </select>
      </div>

      <div className="flex space-x-4">
        <div className="flex-1">
          <label htmlFor="gradeLevel" className="block text-sm font-medium text-gray-700 mb-1">Grade</label>
          <select
            id="gradeLevel"
            name="gradeLevel"
            value={formData.gradeLevel}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
            required
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((grade) => (
              <option key={grade} value={grade.toString()}>
                {grade}{['1', '2', '3'].includes(grade.toString()) ? ['st', 'nd', 'rd'][grade - 1] : 'th'}
              </option>
            ))}
          </select>
        </div>

        <div className="flex-1">
          <label htmlFor="numberOfQuestions" className="block text-sm font-medium text-gray-700 mb-1">Questions</label>
          <input
            type="number"
            id="numberOfQuestions"
            name="numberOfQuestions"
            value={formData.numberOfQuestions}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
            min="1"
            max="50"
            required
          />
        </div>
      </div>

      {/* Question Types */}
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Question Types
        </label>
        <div className="space-y-2 pl-1">
          <label className="flex items-center">
            <input
              type="checkbox"
              value="mcq"
              checked={formData.questionTypes.includes('mcq')}
              onChange={handleQuestionTypeChange}
              className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <span className="ml-2 text-sm text-gray-700">Multiple Choice</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              value="fillInBlanks"
              checked={formData.questionTypes.includes('fillInBlanks')}
              onChange={handleQuestionTypeChange}
              className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <span className="ml-2 text-sm text-gray-700">Fill in the Blanks</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              value="trueOrFalse"
              checked={formData.questionTypes.includes('trueOrFalse')}
              onChange={handleQuestionTypeChange}
              className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <span className="ml-2 text-sm text-gray-700">True/False</span>
          </label>
          <label className="flex items-center">
            <input
              type="checkbox"
              value="openEnded"
              checked={formData.questionTypes.includes('openEnded')}
              onChange={handleQuestionTypeChange}
              className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <span className="ml-2 text-sm text-gray-700">Open Ended</span>
          </label>
        </div>
      </div>

      <button
        type="submit"
        disabled={isLoading}
        className={`w-full px-4 py-2 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-300 ease-in-out text-sm font-medium ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isLoading ? 'Generating...' : 'Generate worksheet'}
      </button>
    </form>
  );
};

export default WorksheetForm;