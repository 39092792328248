import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import Layout from '../components/Layout';
import SEO from '../config/seo';
import axios from 'axios';

const SUBJECTS_SPREADSHEET_ID = '1Ar7X_3tDUI0v27VAWwVPT1QusBQaE1EE2i9rZajAvw8';
const COLLECTIONS_SPREADSHEET_ID = '18t_JJyzwhL9FeFk9hnz_7JqZIa_HD3PSYbeYzCKoTuI';
const API_KEY = 'AIzaSyA0nuOUtyIOA4bvZZZO6E9-zoZnGHLJHmk';

const SubjectPage = () => {
  const { subjectId } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [subjectsResponse, collectionsResponse] = await Promise.all([
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SUBJECTS_SPREADSHEET_ID}/values/Sheet1!A2:D?key=${API_KEY}`),
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${COLLECTIONS_SPREADSHEET_ID}/values/Sheet1!A2:F?key=${API_KEY}`)
      ]);

      setSubjects(subjectsResponse.data.values.map(([id, name, description, thumbnail]) => ({
        id, name, description, thumbnail
      })));
      setCollections(collectionsResponse.data.values.map(([id, subjectId, title, description, thumbnail, featured]) => ({
        id, subjectId, title, description, thumbnail, featured: featured === 'TRUE'
      })));
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleKeyPress = useCallback((event) => {
    // Secret key combination: Ctrl + Shift + R
    if (event.ctrlKey && event.shiftKey && event.key === 'R') {
      event.preventDefault();
      fetchData();
      console.log('Data refreshed');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const subject = subjects.find(s => s.id === subjectId);
  const subjectCollections = collections.filter(c => c.subjectId === subjectId);

  // Prepare SEO data
  const seoTitle = subject ? `${subject.name} Worksheets | Get Worksheet` : 'Subject Worksheets | Get Worksheet';
  const seoDescription = subject 
    ? `Free printable ${subject.name.toLowerCase()} worksheets for students. Download and print high-quality educational materials.`
    : 'Free printable subject worksheets for students. Find and download high-quality educational materials.';
  const seoImage = subject?.thumbnail || 'https://getworksheet.co/Default.png';
  const seoUrl = `https://getworksheet.co/worksheets/${subjectId}`;

  if (loading) return <Layout><SEO title={seoTitle} description={seoDescription} image={seoImage} url={seoUrl} /><div>Loading collections...</div></Layout>;
  if (error) return <Layout><SEO title={seoTitle} description={seoDescription} image={seoImage} url={seoUrl} /><div>Error: {error}</div></Layout>;
  if (!subject) return <Layout><SEO title={seoTitle} description={seoDescription} image={seoImage} url={seoUrl} /><div>Subject not found</div></Layout>;

  return (
    <Layout>
      <SEO 
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
      />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">{subject.name} Worksheets</h1>
        <p className="text-xl text-gray-600 mb-8">{subject.description}</p>
        
        {subjectCollections.length === 0 ? (
          <p>No collections found for this subject.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {subjectCollections.map(collection => (
              <Link key={collection.id} to={`/worksheets/${subjectId}/${collection.id}`} className="block">
                <div className="border p-4 rounded hover:shadow-lg transition-shadow duration-300">
                  {collection.thumbnail && (
                    <img 
                      src={collection.thumbnail} 
                      alt={collection.title} 
                      className="w-full h-40 object-cover mb-4 rounded" 
                    />
                  )}
                  <h3 className="text-xl font-semibold mb-2">{collection.title}</h3>
                  <p className="text-gray-600">{collection.description}</p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SubjectPage;