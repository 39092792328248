import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import SEO from '../config/seo';
import axios from 'axios';
import { X, Download, ArrowDownToLine, Calendar } from 'lucide-react';
import { trackPageView, trackEvent } from '../analytics';
import InterlinkSection from '../components/InterlinkSection';
import TextToParagraphs from '../components/TextToParagraphs';
import GoogleAdSense from '../components/GoogleAdsense';

const SUBJECTS_SPREADSHEET_ID = '1Ar7X_3tDUI0v27VAWwVPT1QusBQaE1EE2i9rZajAvw8';
const COLLECTIONS_SPREADSHEET_ID = '18t_JJyzwhL9FeFk9hnz_7JqZIa_HD3PSYbeYzCKoTuI';
const WORKSHEETS_SPREADSHEET_ID = '1C9IkJ9yew2XBKJVu9dnpXL589l9yYttyfnX6nTc4Sls';
const API_KEY = 'AIzaSyA0nuOUtyIOA4bvZZZO6E9-zoZnGHLJHmk';



const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// Schema Component
const WorksheetCollectionSchema = ({ collection, worksheets, subject }) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name: collection.title,
    description: collection.description,
    dateModified: collection.dateModified || new Date().toISOString(),
    datePublished: collection.datePublished || new Date().toISOString(),
    provider: {
      '@type': 'Organization',
      name: 'GetWorksheet',
      url: 'https://getworksheet.co'
    },
    about: {
      '@type': 'Thing',
      name: subject.name,
      description: subject.description
    },
    itemListElement: worksheets.map((worksheet, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'LearningResource',
        name: worksheet.title,
        description: worksheet.description,
        thumbnailUrl: worksheet.thumbnail,
        downloadUrl: worksheet.pdfUrl,
        educationalLevel: 'Beginner',
        learningResourceType: 'Worksheet',
        dateModified: collection.dateModified || new Date().toISOString(),
        datePublished: collection.datePublished || new Date().toISOString(),
        publisher: {
          '@type': 'Organization',
          name: 'GetWorksheet',
          url: 'https://getworksheet.co'
        }
      }
    }))
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

const WorksheetCollectionPage = () => {
  const { subjectId, collectionId } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [collections, setCollections] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWorksheet, setSelectedWorksheet] = useState(null);
  const popupRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [subjectsResponse, collectionsResponse, worksheetsResponse] = await Promise.all([
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SUBJECTS_SPREADSHEET_ID}/values/Sheet1!A2:D?key=${API_KEY}`),
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${COLLECTIONS_SPREADSHEET_ID}/values/Sheet1!A2:L?key=${API_KEY}`),
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${WORKSHEETS_SPREADSHEET_ID}/values/Sheet1!A2:F?key=${API_KEY}`)
      ]);

      const processedSubjects = subjectsResponse.data.values.map(([id, name, description, thumbnail]) => ({
        id, name, description, thumbnail
      }));

      const processedCollections = collectionsResponse.data.values.map(([
        id, 
        subjectId, 
        title, 
        description, 
        thumbnail, 
        featured, 
        introText, 
        midText, 
        endText, 
        datePublished,
        dateModified
      ]) => ({
        id,
        subjectId,
        title,
        description,
        thumbnail,
        featured: featured === 'TRUE',
        introText,
        midText,
        endText,
        datePublished: datePublished || new Date().toISOString(),
        dateModified: dateModified || new Date().toISOString()
      }));

      const processedWorksheets = worksheetsResponse.data.values.map(([id, collectionId, title, description, pdfUrl, thumbnail]) => ({
        id, collectionId, title, description, pdfUrl, thumbnail
      }));

      setSubjects(processedSubjects);
      setCollections(processedCollections);
      setWorksheets(processedWorksheets);

      // console.log('Fetched Subjects:', processedSubjects);
      // console.log('Fetched Collections:', processedCollections);
      // console.log('Fetched Worksheets:', processedWorksheets);

      setLoading(false);

      trackEvent('worksheet_collection_loaded', {
        subjectId,
        collectionId,
        worksheetsCount: processedWorksheets.length
      });
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again later.');
      setLoading(false);

      trackEvent('worksheet_collection_load_error', {
        subjectId,
        collectionId,
        error: err.message
      });
    }
  };

  useEffect(() => {
    fetchData();
    trackPageView(`/worksheets/${subjectId}/${collectionId}`);
  }, [subjectId, collectionId]);

  console.log('Current State:', { subjects, collections, worksheets, selectedWorksheet });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSelectedWorksheet(null);
        trackEvent('worksheet_popup_closed', {
          subjectId,
          collectionId
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [subjectId, collectionId]);

  const openPopup = (worksheet) => {
    setSelectedWorksheet(worksheet);
    trackEvent('worksheet_popup_opened', {
      worksheetId: worksheet.id,
      worksheetTitle: worksheet.title,
      collectionId,
      subjectId
    });
  };

  const downloadPDF = async (pdfUrl, title, worksheetId) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
      trackEvent('worksheet_pdf_downloaded', {
        worksheetId,
        worksheetTitle: title,
        collectionId,
        subjectId
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
      alert('Failed to download PDF. Please try again.');
      trackEvent('worksheet_pdf_download_error', {
        worksheetId,
        worksheetTitle: title,
        collectionId,
        subjectId,
        error: error.message
      });
    }
  };

  const handleBundleClick = (source) => {
    trackEvent('worksheet-bundle-clicked-CP', {
      page: 'worksheet_collection',
      source: source
    });
    window.open('https://worksheetmaster3.gumroad.com/l/worksheet-bundle', '_blank');
  };

  if (loading) return <Layout><div>Loading...</div></Layout>;
  if (error) return <Layout><div>Error: {error}</div></Layout>;

  const subject = subjects.find(s => s.id === subjectId);
  const collection = collections.find(c => c.id === collectionId);
  const collectionWorksheets = worksheets.filter(w => w.collectionId === collectionId);

  if (!subject || !collection) return <Layout><div>Worksheet collection not found</div></Layout>;

  const halfwayPoint = Math.ceil(collectionWorksheets.length / 2);
  const firstHalfWorksheets = collectionWorksheets.slice(0, halfwayPoint);
  const secondHalfWorksheets = collectionWorksheets.slice(halfwayPoint);

  return (
    <Layout>
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12"> {/* Modified this line */}
      <h1 className="text-4xl font-bold mb-2 text-gray-800">{collection.title}</h1>
        {collection.dateModified && (
          <div className="flex items-center text-sm text-gray-600 mb-6">
            <Calendar className="w-4 h-4 mr-2" />
            <span>Last updated: {formatDate(collection.dateModified)}</span>
          </div>
        )}

       {/* Add Schema markup */}
       {collection && subject && collectionWorksheets.length > 0 && (
        <WorksheetCollectionSchema 
          collection={collection}
          worksheets={collectionWorksheets}
          subject={subject}
        />
      )}

        
        {/* New Bundle Offer Banner */}
        {/* <div className="bg-gradient-to-r from-blue-500 to-purple-600 text-white p-4 rounded-lg mb-8">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <p className="text-lg font-semibold mb-2 sm:mb-0">Get 88 worksheets bundle for just $2 - Limited time</p>
            <button 
              onClick={() => handleBundleClick('collection_page_banner')}
              className="bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-gray-100 transition-colors duration-300 flex items-center"
            >
              <ArrowDownToLine className="w-5 h-5 mr-2" />
              Get Bundle
            </button>
          </div>
        </div> */}
        
        {collection.introText && (
          <TextToParagraphs 
            text={collection.introText} 
            className="text-md text-gray-700 mb-8 leading-relaxed"
          />
        )}
        
        <WorksheetGrid 
          worksheets={firstHalfWorksheets} 
          openPopup={openPopup} 
          downloadPDF={downloadPDF}
          subjectId={subjectId}
          collectionId={collectionId}
        />

         {/* Insert the AdSense component here */}
         <GoogleAdSense />
        
        {collection.midText && (
          <TextToParagraphs 
            text={collection.midText} 
            className="my-8 prose prose-lg max-w-none"
          />
        )}
        
        {secondHalfWorksheets.length > 0 && (
          <WorksheetGrid 
            worksheets={secondHalfWorksheets} 
            openPopup={openPopup} 
            downloadPDF={downloadPDF}
            subjectId={subjectId}
            collectionId={collectionId}
          />
        )}
        
        {collection.endText && (
          <TextToParagraphs 
            text={collection.endText} 
            className="mt-8 prose prose-lg max-w-none"
          />
        )}


<InterlinkSection />
        {selectedWorksheet && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div ref={popupRef} className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto relative">
              <button 
                onClick={() => {
                  setSelectedWorksheet(null);
                  trackEvent('worksheet_popup_closed', {
                    worksheetId: selectedWorksheet.id,
                    collectionId,
                    subjectId
                  });
                }}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <X size={24} />
              </button>
              <div className="p-4">
                <img 
                  src={selectedWorksheet.thumbnail} 
                  alt={selectedWorksheet.title} 
                  className="w-full object-contain mb-4" 
                  style={{ maxHeight: '60vh' }}
                />
                {/* <div className="flex justify-between items-center mb-4">
                  <button 
                    onClick={() => handleBundleClick('modal_popup')}
                    className="flex-grow text-center bg-gradient-to-r from-blue-500 to-purple-600 text-white px-4 py-2 rounded-lg hover:opacity-90 transition-opacity flex items-center justify-center"
                  >
                    <ArrowDownToLine className="w-5 h-5 mr-2" />
                    Get 88 worksheets for just $2 - Limited time deal!
                  </button>
                </div> */}
                <div className="flex justify-center">
                  <button 
                    onClick={() => downloadPDF(selectedWorksheet.pdfUrl, selectedWorksheet.title, selectedWorksheet.id)}
                    className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition-colors"
                  >
                    Download PDF
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {collection && (
        <SEO
          title={`${collection.title} Worksheets | Get worksheet`}
          description={collection.description || `Download ${collection.title} worksheets for free.`}
          image={collection.thumbnail}
          url={`https://getwoksheet.co/worksheets/${subjectId}/${collectionId}`}
        />
      )}
    </Layout>
  );
};

const WorksheetGrid = ({ worksheets, openPopup, downloadPDF, subjectId, collectionId }) => (
  <div className="grid grid-cols-1 gap-10">
    {worksheets.map(worksheet => (
      <div 
        key={worksheet.id} 
        className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-200 flex flex-col sm:flex-row cursor-pointer"
        onClick={(e) => {
          // Prevent opening popup if clicking on the download button
          if (!e.target.closest('.download-button')) {
            openPopup(worksheet);
          }
        }}
      >
        <div className="w-full sm:w-1/3 overflow-hidden">
          {worksheet.thumbnail && (
            <img 
              src={worksheet.thumbnail} 
              alt={worksheet.title} 
              className="w-full h-full object-cover transition-transform duration-300 hover:scale-105" 
              onLoad={() => trackEvent('worksheet_thumbnail_loaded', {
                worksheetId: worksheet.id,
                collectionId,
                subjectId
              })}
              onError={() => trackEvent('worksheet_thumbnail_error', {
                worksheetId: worksheet.id,
                collectionId,
                subjectId
              })}
            />
          )}
        </div>
        <div className="p-6 flex flex-col justify-between flex-grow">
          <div>
            <h2 className="text-xl font-semibold mb-3 text-gray-800">{worksheet.title}</h2>
            <p className="text-md text-gray-600 mb-4 leading-relaxed">{worksheet.description}</p>
          </div>
          <button 
            onClick={(e) => {
              e.stopPropagation(); // Prevent card click event
              downloadPDF(worksheet.pdfUrl, worksheet.title, worksheet.id);
            }}
            className="download-button bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors inline-flex items-center self-start text-md"
          >
            <Download className="mr-2" size={18} />
            Download PDF
          </button>
        </div>
      </div>
    ))}
  </div>
);
export default WorksheetCollectionPage;